<template>
    <LiefengContent :isBack="true" @backClick="backClick">
        <template v-slot:title>业务点定义</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增业务点</Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </div>

            <LiefengModal :title="currentFormData.eventId ? '修改业务点' : '新增业务点'" width="30%" height="350px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="业务点名称" prop="eventName">
                            <Input placeholder="请输入名称" v-model.trim="currentFormData.eventName"></Input>
                        </FormItem>
                        <FormItem label="描述" prop="eventDesc">
                            <Input type="textarea" v-model.trim="currentFormData.eventDesc" placeholder="简单描述" maxlength="500"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUpload,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            menuList: [],
            tableColumns: [
                {
                    title: "业务点名称",
                    width: 250,
                    key: "eventName",
                    align: "center",
                },
                {
                    title: "描述",
                    minWidth: 200,
                    key: "eventDesc",
                    align: "center",
                },
                {
                    title: "业务点ID",
                    minWidth: 200,
                    key: "eventCode",
                    align: "center",
                },
                {
                    title: "启用状态",
                    key: "eventStatus",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("i-switch", {
                                props: {
                                    value: params.row.eventStatus,
                                    size: "large",
                                    "true-value": "1",
                                    "false-value": "2",
                                },
                                scopedSlots: {
                                    open: () => h("span", "启用"),
                                    close: () => h("span", "禁用"),
                                },
                                on: {
                                    "on-change": val => {
                                        this.changeSwitch(params.row.eventId)
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    eventDesc: params.row.eventDesc,
                                                    eventId: params.row.eventId,
                                                    eventName: params.row.eventName,
                                                    oemCode: parent.vue.oemInfo.oemCode,
                                                }
                                                this.showImage = false
                                                this.$nextTick(() => {
                                                    this.showImage = true
                                                })
                                                this.addModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.deleteData(params.row.eventId)
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "删除" //1001
                                // ),
                            ]
                        )
                    },
                },
            ],
            currentFormData: {},
            validateForm: {
                eventName: [{ required: true, message: "请输入业务点名称", trigger: "blur" }],
                eventDesc: [{ required: true, message: "请输入描述", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            priceModal: {
                status: false,
            },
            eventId: "",
            type: "add",

            showImage: false,
        }
    },
    methods: {
        backClick() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/mallhomepage"
                })
            }
        },
        //   切换状态
        changeSwitch(eventId) {
            this.$post("/gateway/sy-points/api/sypointes/pool/updateEventStatus", {
                eventId,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.currentFormData = {}
                        this.getList(this.page)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.poolDesc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败",
                    })
                })
        },
        // 奖品设置确定接口
        priceSubmit() {
            this.$refs.priceModal.sumbit()
        },
        addModalData(status) {
            this.addModal.status = status
        },
        priceModalData(status) {
            this.priceModal.status = status
        },

        submit() {
            this.$refs.form.validate(status => {
                if (status) {
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    var url = ""
                    if (cFormData.eventId && cFormData.eventId != "") {
                        url = "/gateway/sy-points/api/sypointes/pool/updatePointsEvent"
                    } else {
                        url = "/gateway/sy-points/api/sypointes/pool/addPointsEvent"
                    }
                    this.$post(
                        url,
                        {
                            ...cFormData,
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            this.addModalData(false)
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.currentFormData = {}
                                this.getList(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.currentFormData = {
                eventName: "",
                eventDesc: "",
                oemCode: parent.vue.oemInfo.oemCode,
            }

            this.addModalData(true)
        },
        deleteData(id) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    return
                    this.$post("", {
                        id,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getList()
                            return
                        }
                    })
                },
            })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/pool/queryEventByPage", {
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less'>
/deep/.ivu-form-item-content {
    display: flex !important;
}
.ivu-form-item-label {
    display: flex !important;
}
</style>
